import { Howl } from 'howler'
import { musicMapping } from '../data/music.js'

const rawFiles = Object.assign(
	import.meta.glob('../assets/musics/*.mp3', { eager: true }),
	import.meta.glob('../assets/musics/*.mp4', { eager: true })
)
let _musics = []

// Fonction utilitaire qui extrait l'extension à partir d'une URL ou d'un chemin
const getFileExtension = (url) => {
	const match = url.match(/\.([a-zA-Z0-9]+)(\?|$)/)
	return match ? match[1] : ''
}

// Vérifie si la chaîne est une URL (commence par http ou https)
const isValidUrl = (string) => /^https?:\/\//.test(string)

// Fonction asynchrone pour télécharger et convertir en blob un média externe
const loadExternalFile = async (fileUrl) => {
	try {
		const response = await fetch(fileUrl)

		if (!response.ok) {
			console.error(`Erreur lors du téléchargement du média externe : ${fileUrl}`)
			return null
		}

		const blob = await response.blob()

		return URL.createObjectURL(blob)
	} catch (error) {
		console.error('Erreur lors du chargement du média externe :', error)
		return null
	}
}

// La fonction initMusics récupère les musiques internes ET externes
export const initMusics = async () => {
	// Chargement des fichiers internes
	_musics = Object.entries(rawFiles).map(([_, module]) => {
		const file = module.default
		const splits = file.split('/')
		const slug = (import.meta.env.DEV
			? splits[splits.length - 1]
			: splits[splits.length - 1].split('-').slice(0, -1).join('-')
		).replace('.mp3', '').replace('.mp4', '')
		return {
			slug,
			file,
			howl: new Howl({
				src: [file],
				volume: 1
			})
		}
	})

	// Chargement des musiques externes définies dans musicMapping
	const externalPromises = []

	Object.keys(musicMapping).forEach(groupKey => {
		const group = musicMapping[groupKey]
		Object.keys(group).forEach(musicKey => {
			const entry = group[musicKey]
			// Si l'entrée comporte une URL, on charge le média externe
			if (entry.url && isValidUrl(entry.url)) {
				const promise = loadExternalFile(entry.url).then(blobUrl => {
					if (blobUrl) {
						const extension = getFileExtension(entry.url)
						_musics.push({
							// On peut utiliser l'URL comme identifiant (slug) ou adapter selon vos besoins
							slug: entry.url,
							file: blobUrl,
							howl: new Howl({
								src: [blobUrl],
								volume: entry.volume !== undefined ? entry.volume : 1,
								format: extension ? [extension] : undefined
							})
						})
					}
				})
				externalPromises.push(promise)
			}
		})
	})

	await Promise.all(externalPromises)

	console.info('🎵 INIT MUSICS (' + _musics.length + ')')
}

// La fonction playMusic se contente désormais de jouer une musique déjà initialisée
export const playMusic = ({ slug }) => {
	const music = _musics.find(s => s.slug === slug)
	if (music && !music.howl.playing()) {
		stopAllMusics()
		music.howl.loop(true)
		music.howl.play()
	}
}

export const stopMusic = (music) => {
	if (music.slug) {
		if (!music?.howl) music = _musics.find(m => m.slug === music.slug)
		if (music?.howl) {
			music.howl.stop()
		}
	}
}

export const stopAllMusics = () => {
	_musics
		.forEach(m => stopMusic(m))
}

export const changeMusicsVolume = ({ volume }) => {
	_musics.forEach(music => {
		music.howl.volume(volume)
	})
}

export const muteAllMusics = () => {
	_musics.forEach(music => {
		music.howl.mute(true)
	})
}

export const unmuteAllMusics = () => {
	_musics.forEach(music => {
		music.howl.mute(false)
	})
}
