export const myGroups = async({ filter = JSON.stringify({ value: '' }) }) => {
	return await get('myGroups', { filter })
}

export const groupDisplay = async ({ id, filter = JSON.stringify({ value: '' }), page = 1 }) => {
	return await get('groupDisplay', { id, filter, page })
}

export const memberStats = async({ memberIds, category = JSON.stringify({ value: '' }) }) => {
	return await get('memberStats', { memberIds, category })
}

export const docuDecouverteByCategory = async({ category = JSON.stringify({ value: '' }), groupId, tag = JSON.stringify({ value: '' }) }) => {
	return await get('docuDecouverteByCategory', { category, groupId, tag })
}

export const commentsByElement = async({ elementId, groupId, page }) => {
	return await get('commentsByElement', { elementId, groupId, page })
}

export const setItinerary = async({ category = JSON.stringify({ value: '' }), tag }) => {
	return await get('setItineraire', { category, tag })
}

export const gamesByAlternative = async() => {
	return await get('gamesByAlternative')
}

export const createGroup = async({ name }) => {
	return await post('createGroup', { name })
}

export const deleteGroup = async({ id }) => {
	return await post('deleteGroup', { id })
}

export const deleteMember = async({ userId }) => {
	return await post('deleteMember', { userId })
}

export const editMember = async({ userId, userName, firstName }) => {
	return await post('editMember', { userId, userName, firstName })
}

export const resetMemberPassword = async({ groupId, memberId }) => {
	return await post('resetMemberPassword', { groupId, memberId })
}
