export const moveElementToCenterOfScreen = async ({ $el, scale = 1, zIndex = false, delay = 750 }) => {
	const app = document.querySelector('#app')?.getBoundingClientRect()
	const rect = $el?.getBoundingClientRect()
	if (app && rect) {
		const x = (app.width / 2 - rect.width / 2 - (rect.x - app.x))
		const y = (app.height / 2 - rect.height / 2 - (rect.y - app.y))
		$el.style.pointerEvents = 'none'
		$el.style.transition = '0.25s cubic-bezier(0.470, 0.000, 0.745, 0.715)'
		$el.style.transform = 'translate(' + x + 'px, ' + y + 'px) scale(' + scale + ')'
		if (zIndex) $el.style.zIndex = zIndex
		if (delay) await wait(delay)
	}
}

export const resetElementToCenterOfScreen = ({ $el }) => {
	$el.style.pointerEvents = ''
	$el.style.transition = ''
	$el.style.transform = ''
	$el.style.zIndex = ''
}

import { medias } from '@affordancestudio/engage-game'

export function getJournal(category) {
	const DEFAULTS = {
		cover: 'user/journal-cover.png',
		color: '#CCCCCC',
		characterImage: 'badge-main-character.svg',
		suitcase: 'default'
	}

	let categoryId
	if (typeof category === 'string') {
		try {
			const parsedCategory = JSON.parse(category)
			categoryId = parsedCategory?.value || category
		} catch (e) {
			categoryId = category
		}
	} else if (typeof category === 'object' && category?.id) {
		categoryId = category?.id
	} else if (typeof category === 'object' && category?.value) { 
		categoryId = category?.value
	} else {
		return DEFAULTS
	}

	const mapStore = useMapStore()
	if (!mapStore?.categories?.data) {
		return DEFAULTS
	}

	categoryId = String(categoryId)?.trim()

	const matchedCategory = mapStore?.categories?.data?.find(i => i?.id?.trim() === categoryId)

	if (!matchedCategory) {
		return DEFAULTS
	}

	const journalCoverName = matchedCategory?.document?.['journal-img']
	const coverPath = journalCoverName ? `user/${journalCoverName}.png` : DEFAULTS?.cover
	const journalColor = matchedCategory?.document?.['journal-color'] || DEFAULTS?.color
	const characterMedia = matchedCategory?.clientMedias?.find(media =>
		media?.tags?.includes('character') || media?.tags?.includes('character-bottom-right')
	)
	const characterImage = characterMedia?.id ? medias.get({ id: characterMedia?.id })?.base64 || null : DEFAULTS?.characterImage
	const suitcase = matchedCategory?.document?.suitcase || DEFAULTS?.suitcase
	
	return { cover: coverPath, color: journalColor, characterImage, suitcase }
}


export function getBookmark(sections){
	return transpose(sections)
		.get('clientFiles')
		.flat()
		?.filter(cf => 	cf?.tags?.includes('fin_itineraire'))
		?.[0]
}