export const useMusicService = ({ musicMapping, mapStore }) => {
	const getCurrentCategorySlug = (store) =>
		store?.currentCategoryDetails?.slug ?? ''

	const getMusicDataByTag = (tag) =>
		musicMapping?.[getCurrentCategorySlug(mapStore)]?.[tag ?? '']

	return {
		play: (musicTag) => {
			const musicData = getMusicDataByTag(musicTag)
			if (musicData) {
				// On construit le slug en utilisant "name" s'il existe, sinon "url"
				const slug = musicData.name || musicData.url
				const volume = musicData.volume ?? 1

				if (slug) {
					// Potentiellement, on pourrait appliquer "volume" ici sur la Howl
					playMusic({ slug, volume })
				}
			}
		}
	}
}