export const musicMapping = {
	contes_legendes: {
		'city-map': { name: 'comptes-legendes-city-map', volume: 1 },
		'game': { name: 'comptes-legendes-game', volume: 1 },
	},
	exploration_francophonie: {
		'city-map': { name: 'exploration-francophonie-city-map', volume: 1 },
		'game': { name: 'exploration-francophonie-game', volume: 1 },
	},
	passeport_connexions: {
		'city-map': { url: 'https://lio-medias.s3.us-east-1.amazonaws.com/passeport-city-map.mp3', volume: 0.25 },
		'game': { name: 'passeport-game', volume: 1 },
	},
}