import { medias } from '@affordancestudio/engage-game'
import { useAppStore } from './app-store'
import { useUserStore } from './user-store'
import { json, transpose } from '@/js/utils'
import { getLootItemService } from '@/services/section-bg-service'
import { getBookmark, getJournal } from '@/helpers/game-helper'

export const useMapStore = defineStore({
	id: 'map',

	state: () => ({
		sections: null,
		currentArea: null,
		categories: null,
		currentCategory: null
	}),

	getters: {
		suitcaseDocuDecouverteIcon(){
			//TODO: refactorer quand on mettra à jour l'architecture pour les itineraires pour faciliter l'obtencion des iconesDocuDecouverte
			return this.sections && this.sections?.length
				? transpose(this.sections)
					.find(s => s?.document?.tag === 'ontario')
					.get('clientFiles.[].clientMedias')
					.flatten()
					.flatten()
					.find(cm => cm?.tags?.includes('docu-decouverte-icon'))
					.map(cm => medias.get({ id: cm.id })?.base64 ?? '')
					.value
				: null
		},

		areas() {
			const userStore = useUserStore()
			
			const itinerary = transpose(this.categories)
				.get('data')
				.find(it => it.id === this.currentCategoryId)
				.value
			
			const lootItemService = getLootItemService(itinerary)

			const sections = this.sections?.map(s => ({
				...s,
				displayJournal: true,
				clientGames: s?.clientGames?.map(cg => {
					const itemInventory = userStore.findReward(cg.id)
					const nbStars = itemInventory?.amount ?? 0
					const sectionName = s.document?.tag?.toLowerCase()
					const lootItem = lootItemService.findBySectionName(sectionName)
					return {
						...cg,
						reward: itemInventory || cg?.status === 'completed' ? cg.clientRewards?.[0] : null,
						itemInventory,
						nbStars,
						showStars: cg?.status === 'completed',
						type: lootItem?.type,
						backgroundImage: lootItem ? medias.get({ id: lootItem.media_id })?.base64 : '',
					}
				}) ?? [],
				characterBadges: s.clientFiles?.filter(f => f?.tags?.includes('character-badge')).map(f => ({ ...f, illustration: medias.get({ id: f?.clientMedias?.[0]?.id }) })) ?? [],
				docuDecouvertes: s.clientFiles?.filter(f => f?.tags?.includes('docu-decouverte') && !f?.tags?.includes('hidden')).map(f => ({ ...f, illustration: medias.get({ id: f?.clientMedias?.[0]?.id }) })) ?? [],
				areaName: s.document?.tag,
				zoomLevel: parseFloat(s.document?.zoom) || 2,
			}))
				?.filter(s => s?.id && s?.areaName) || []

			const bookmark = getBookmark(sections)
			if(bookmark?.status === 'completed'){
				sections.push(createBookmarkSection(bookmark))
			}

			return sections
		},

		zoomLevel() {
			return this.currentArea?.zoomLevel || 1
		},

		games() {
			return transpose(this.areas).get('clientGames').flat()
		},

		characters() {
			return transpose(this.areas).get('characterBadges').flat()
		},

		journal() {
			return getJournal(this.currentCategory)
		},

		currentCategoryId(){
			return json().parse(this.currentCategory).getOrDefault({})?.value
		},

		currentCategoryDetails(){
			return this.categories?.data?.find(c => c?.id === this.currentCategoryId) || {}
		},

	},

	actions: {
		setCategory(index, categories) {
			const category = categories?.data?.[index]
			this.currentCategory = JSON.stringify({ value: category?.id })
			const preference = useUserStore()?.userPreferences
			useUserStore().setUserPreferences({...preference, itinerary: this.currentCategory})
		},

		async updateCategories() {
			const category = await categories() || null
			this.categories = category
		},

		async updateMap() {
			try{
				useAppStore().loading = true
				const itinerary = await this.currentCategory
				this.sections = await myMap({ category: itinerary })
			}finally{
				useAppStore().loading = false
			}
		}

	},

})


function createBookmarkSection(bookmark){
	const itemInventory = useUserStore().findReward(bookmark?.id)
	const lootItem = itemInventory?.clientLootItem
	
	return {
		id: 'bookmark',
		showProgress: false,
		document: {
  		title: lootItem?.name ?? '',
		},

		clientGames: [
			{
				type: 'bookmark',
				image: medias.get({ id: lootItem?.clientMedias?.[0]?.id })?.base64,
				title: lootItem?.document?.title,
				link: lootItem?.document?.url,
				reward: {
					tags: ['signet']
				}
			}
		],
	}
}


if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useMapStore, import.meta.hot))
}
