import { getBookmark } from '@/helpers/game-helper'
import { endItinerary } from '@/requests/game-requests'
import router from '@/router'
import {useMapStore} from '@/stores/map-store'
import {useUserStore} from '@/stores/user-store'
import { transpose } from '@/js/utils'
import {range} from 'lodash'

export const useGameStore = defineStore({
	id: 'game',

	state: () => ({
		loading: false,
		ready: false,
		gameId: null,
		progressionId: null,
		progression: null,
		stepRewards: [],
		currentQuestionIndex: 0,
		selectedAnswer: null,
		explanationVisible: false,
		isFinalFeedback: false,
		isFeedbackAvailable: false,
		displayGame: true,
		activityCount: 0,
		currentActivity: 0
	}),

	getters: {
		currentContent() {
			return this.progression?.currentContent
		},
		activities() {
			return range(0, this.activityCount ?? 0)
		},
		stepType() {
			const layout = this.currentContent?.layout
			return {
				'intro': 'talk',
				'association-2-set': 'matching',
				'conversation': 'talk',
				'trivia': 'trivia',
				'preferences': 'preferences',
				'accounts': 'accounts',
				'select_option_card': 'preferences',
				'select_with_stars': 'accounts',
				'event-result': 'rewards',
				'scramble': 'scramble'
			}[layout] || layout
		},
		currentGame() {
			if (this.gameId) {
				return useMapStore().games.find(g => g.id === this.gameId)
			}
			return null
		},
		color() {
			// return 'orange'
			// return 'yellow'
			// return 'blue'
			// return 'light-blue'
			return this.currentGame?.document?.hex || 'orange'
		},
		questions() {
			return this.progression?.currentContent?.questions
		},
		currentQuestion() {
			if (this.questions?.length && this.currentQuestionIndex < this.questions.length) {
				const q = this.progression.currentContent.questions[this.currentQuestionIndex]
				const answers = q?.contentQuestionAnswers?.map(a => ({
					...a,
					answerTexts: a.contentTexts.filter(t => !t?.tags?.includes?.('feedback')),
					feedbackTexts: a.contentTexts.filter(t => t?.tags?.includes?.('feedback')),
				}))
				return {
					...q,
					questionTexts: q.contentTexts.filter(t => !t?.tags?.includes?.('feedback')),
					feedbackTexts: q.contentTexts.filter(t => t?.tags?.includes?.('feedback')),
					// tipTexts: q.contentTexts.filter(t => t.tags.includes('tip')),
					answers,
				}
			}
			return null
		},
		resultType() {
			return this.selectedAnswer?.score === 1 ? 'correct' : 'wrong'
		}
	},

	actions: {

		async initGame({ id }) {
			this.$reset()
			this.loading = true
			const progression = await startGame({ id })
			this.activityCount = progression?.activityCount ?? 0
			this.currentActivity = 0
			this.loading = false
			this.displayGame = true
			await this.updateProgression({ result: { progression }, gameId: id })
		},

		async nextStep({ result = null, target = null } = {}) {
			this.loading = true
			if (!this.isFeedbackAvailable) this.displayGame = false

			if (!result) result = await nextStep({ progressionId: this.progressionId, target })

			await this.updateProgression({ result })

			this.selectedAnswer = null
			this.explanationVisible = false
			this.currentQuestionIndex = 0
			this.loading = false
		},

		nextActivity(){
			this.currentActivity++
		},

		async endGame() {
			if (!this.isFeedbackAvailable) this.displayGame = false

			this.currentActivity = 0
			// await Promise.all([
			// 	useUserStore().updateUser(),
			// 	useMapStore().updateMap()
			// ])

			await router.push({ name: 'city' })
			
			const areaName = useMapStore().currentArea?.document?.tag?.toLowerCase()
			const itineraryName = useMapStore()?.currentCategoryDetails?.slug
			
			const sectionId =
				(useMapStore()?.sections ?? [])
					.flatMap(section => section.clientGames.map(game => ({ id: section.id, game })))
					.find(x => x.game.id === this.gameId)
					?.id

			const isSectionCompleted =
				// import.meta.env?.VITE_APP_EASTER_EGG_SECTION_COMPLETED === 'true' ??
				(useMapStore()?.sections ?? [])
					.filter(section => section.id === sectionId)
					.flatMap(section => section.clientGames)
					.every(game => game.status === 'completed')
			
			if (isSectionCompleted) {
				const parameters = [
					{tag: `area-${areaName}`},
					{tag: `itinerary-${itineraryName}`},
					{tag: 'on-end-area'},
				]
				this.$tutorialService.playWithParameters({parameters})
			}

			const isItineraryCompleted =
				// import.meta.env?.VITE_APP_EASTER_EGG_ITINERARY_COMPLETED === 'true' ??
				(useMapStore()?.sections ?? [])
					.every(section => {
						return section.clientGames?.every(game => {
							return game.status === 'completed'
						})
					})

			if (isItineraryCompleted) {
				await this.getBookmarkIfExists()
				const {updateUser} = useUserStore()
				const {updateMap} = useMapStore()
				await updateUser?.()
				await updateMap?.()
				const parameters = [
					{tag: `itinerary-${itineraryName}`},
					{tag: 'on-end-itinerary'},
				]
				this.$tutorialService.playWithParameters({parameters})
			}

			this.$reset()
		},

		async getBookmarkIfExists(){
			const { sections } = useMapStore()
			const bookmark = getBookmark(sections)
			if(bookmark?.status !== 'completed'){
				await endItinerary({ fileId: bookmark?.id})
			}
		},

		async updateProgression({ result, gameId = null }) {
			if (result?.gameCompleted) {
				await Promise.all([
					useUserStore().updateUser(),
					useMapStore().updateMap()
				])
				if (result.lootRewards?.length) {
					const reward = getRewardFromResult(this.currentGame, result)
					const score = result?.progression?.gameScore ?? 0
					const gameResult = {score, reward}
					const nbCompletedGames =
						(useMapStore()?.sections ?? [])
							.reduce((count, item) => {
								return count + item?.clientGames?.reduce((subCount, game) => {
									return subCount + (game?.status === 'completed' ? 1 : 0)
								}, 0)
							}, 0)
					
					let parameters = [{tag: 'on-end-game'}]

					if (nbCompletedGames === 1) {
						const tuto = this.$tutorialService.selectOne([
							{tag: 'on-end-game-1'},
							{tag: `itinerary-${useMapStore()?.currentCategoryDetails?.slug}`}
						])
						
						if (tuto && !this.$tutorialService.isViewed({tuto})) {
							parameters = [{tag: 'on-end-game-1'}]
						}
					}
					
					this.$tutorialService.playWithParameters({force: true, parameters, gameResult})
				}
				return this.endGame()
			}

			this.stepRewards = result?.lootRewards || []
			this.progression = result?.progression || null
			if (this.progression?.id) this.progressionId = this.progression.id
			if (gameId) this.gameId = gameId
			this.ready = true
		},

		async sendAnswer(answer) {
			if (!this.selectedAnswer) {
				this.selectedAnswer = cloneDeep(answer)
				playSound({ slug: answer.score ? 'success' : 'wrong' })
				await sendAnswer({
					progressionId: this.progressionId,
					questionId: this.currentQuestion.id,
					answers: [ answer.id ]
				})
			}
		},

		
		async sendMatch({ ids }) {
			if (ids.length > 1) {
				await sendMatch({
					progressionId: this.progressionId,
					matchableElementIds: ids,
				})
			}
		},

		async sendScramble({answers, sentenceId}) {
			await sendScramble({
				progressionId: this.progressionId,
				sentenceId: sentenceId,
				answers: [answers],
			})
		},

		async nextQuestion() {
			if (!this.loading && this.selectedAnswer) {
				this.loading = true
				if (this.questions?.length && this.currentQuestionIndex + 1 < this.questions.length) {
					this.selectedAnswer = null
					this.explanationVisible = false
					this.currentQuestionIndex++
				} else {
					await this.nextStep()
					this.nextActivity()
				}
				this.loading = false
			}
		},

	},

})

function getRewardFromResult(currentGame, result){
	const reward = cloneDeep(currentGame?.clientRewards?.[0] ?? {})
	reward.media_id = transpose(result)
		.get('lootRewards.[]')
		.getFirst()
		.get('reward.clientLootItem.clientMediaIds')
		.getFirst()
		.value
	Object.assign(reward, currentGame?.reward)
	return reward
}

function checkAllGamesCompleted(games) {
	if(!games) return false
	return games?.every?.(game => game?.status === 'completed')
}


if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useGameStore, import.meta.hot))
}
