<template>
	<div class="bubble" :class="[ 'from-' + settings.bubbleFrom, { 'has-difficulty-selection': settings.chooseDifficulty } ]">
		<div class="bg"><div class="bottom" v-bg="'character-talk/bubble-arrow.svg'"></div></div>
		<div v-if="visibleText" class="text">
			<ui-text :text="visibleText" />
			<div v-if="memoryStampReward" class="stamp">
				<memory-stamp :score="score" :reward="memoryStampReward" :nbStars="nbStars" />
			</div>
			<ui-audio-player-button v-if="voiceUrl" :audio-url="voiceUrl" />
		</div>

		<difficulty-selection v-if="settings.chooseDifficulty" data-id="difficulty-selection" @next="() => $emit('next')" />

		<div v-if="settings?.nbDots" class="dots">
			<div v-for="i in settings.nbDots" :key="i" class="dot" :class="{ active: i <= settings.dot }"></div>
		</div>

		<div v-if="skippable" class="skip-button" v-bg="'character-talk/bubble-close.svg'" v-tap="() => $emit('skip')"></div>
	</div>
</template>

<script>
import { medias } from '@affordancestudio/engage-game'
import { urlToBase64 } from '@/js/utils'

export default {
	props: {
		text: { default: "" },
		voice: { default: "" },
		settings: { default: {} },
		gameResult: { default: {} },
		skippable: { default: false },
	},
	data() {
		return {
			voiceUrl: null,
		}
	},
	methods: {
		urlToBase64,
	},
	watch: {
		voice: {
			async handler(value) {
				if (value) {
					const slug = value.replace('[difficulty]', (this?.user?.alternatives?.[0] ? this.$t('difficulties.' + this.user.alternatives[0] + '.voiceSlug') : ''))

					if (slug) {
						let media = medias.findBySlug({ slug })

						if (!media) {
							media = await medias.getMediasByFilter({
								action: 'equal',
								options: 'slug',
								values: [ slug ],
							})

							if (media && media.length) {
								media = media[0].fold(() => {}, x => x?.data?.[0])

								if (media) {
									const {url} = media

									if (url) media.base64 = await this.urlToBase64(url)
								}
							}
						}
						this.voiceUrl = media?.base64
					}
				}
			},
			immediate: true,
		}
	},
	computed: {
		...mapState(useUserStore, [ 'user' ]),
		...mapState(useMapStore, [ 'games' ]),
		score() {
			return this?.gameResult?.score ?? 0
		},
		nbStars(){
			if(!this.gameResult) return 0

			const {score, reward} = this.gameResult

			if (score && reward) {
				return reward?.thresholds?.reduce((acc, threshold) => acc + (score >= threshold.score ? 1 : 0), 0)
			}

			return 0
		},
		visibleText() {
			return this.text.replace('[game-reward]', '')
		},
		memoryStampReward() {
			if (this.gameResult?.reward && this.text.indexOf('[game-reward]') > -1) return this.gameResult.reward
			return null
		},
	},
}
</script>

<style lang="stylus" scoped>

#mobile, #tablet-vertical
	.bubble
		&.from-corner
			right 32px
			bottom 128px
			.bottom
				width 48px
				height 48px
				top 100%
				left 75%
				transform none

#mobile
	.bubble
		font-size 1.7rem
		max-width calc(100vw - 48px)
		margin 24px 8px
		max-height 100vw
		&.from-main-character
			right calc(50% - 24px)
		.skip-button
			left calc(100% - 32px)
			top -56px
		.dots
			gap 6px
			.dot
				width 10px
				height 10px

.bubble
	position absolute
	right 40%
	bottom 90%
	width 450px
	padding 24px
	font-size 2rem
	color dark
	text-align center
	transform-origin bottom right

	&.from-corner
		right calc(100% + 8px)
		bottom 80px
		width 420px
		.bg
			border-radius 16px
			.bottom
				width 48px
				height 48px
				top calc(50% - 16px)
				left 100%
				transform scaleY(-1) rotate(-90deg)

	&.from-top
		width 420px
		.bg
			border-radius 16px
		.bottom
			display none

	&.has-difficulty-selection
		bottom 50%
		right 105%
		width 350px
		.text
			font-weight 500

	&.next-enter-active
		transition 0.35s easeOutBack 0.25s
		will-change transform, opacity
	&.next-enter-from
		transform translate(32px, 32px) rotate(8deg)
		opacity 0
		will-change transform, opacity
	&.next-leave-active
		transition 0.35s easeInQuart
		opacity 0
	&.next-leave-to
		transform translate(-32px, -32px) rotate(-8deg)
		opacity 0

	&.prev-enter-active
		transition 0.35s easeOutBack 0.25s
		will-change transform, opacity
	&.prev-enter-from
		transform translate(-32px, -32px) rotate(-8deg)
		opacity 0
		will-change transform, opacity
	&.prev-leave-active
		transition 0.35s easeInQuart
		opacity 0
	&.prev-leave-to
		transform translate(32px, 32px) rotate(8deg)
		opacity 0

	.bg
		absolute 0
		border-radius 40px
		box-shadow 0 0 12px alpha(#000, 50%)
		background-color #fff
		.bottom
			position absolute
			top 99%
			left calc(50% - 32px)
			width 64px
			height 64px
			background left top no-repeat
			background-size contain

	.text
		::v-deep(.icon-settings)
			display block
			margin 16px auto
			width 120px
			height 120px
			background url('@/assets/img/badge-main-character.svg') center center no-repeat
			background-size contain
			box-shadow 0 0 16px #0F4B5A
			border-radius 50%
		::v-deep(.icon-city-character-badge)
		::v-deep(.icon-city-game-pin)
		::v-deep(.icon-city-passport-game-pin)
		::v-deep(.icon-city-docu-decouverte)
		::v-deep(.icon-makwa)
		::v-deep(.icon-nour)
		::v-deep(.contes-nord)
		::v-deep(.contes-ouest)
		::v-deep(.contes-ontario)
		::v-deep(.contes-quebec)
		::v-deep(.contes-atlantique)
		::v-deep(.livre-carcajou)
		::v-deep(.livre-sedna)
		::v-deep(.livre-castor)
		::v-deep(.livre-rose)
		::v-deep(.livre-paul)
		::v-deep(.livre-sirene)
			display block
			margin 16px auto
			height 96px
			width 100%
			background center center no-repeat
			background-size contain
		::v-deep(.icon-city-character-badge)
			background-image url('@/assets/img/tuto/icon-city-character-badge.png')
		::v-deep(.icon-makwa)
			background-image url('@/assets/img/badge-main-character-makwa.svg')
		::v-deep(.icon-nour)
			background-image url('@/assets/img/badge-main-character-nour.svg')
		::v-deep(.icon-city-game-pin)
			background-image url('@/assets/img/tuto/icon-city-game-pin.png')
		::v-deep(.icon-city-passport-game-pin)
			background-image url('@/assets/img/map/map-pin-passport.png')
		::v-deep(.icon-city-docu-decouverte)
			background-image url('@/assets/img/tuto/icon-city-docu-decouverte.svg')
		::v-deep(.contes-nord)
			background-image url('@/assets/img/badges/contes-nord.png')
		::v-deep(.contes-ouest)
			background-image url('@/assets/img/badges/contes-ouest.png')
		::v-deep(.contes-ontario)
			background-image url('@/assets/img/badges/contes-ontario.png')
		::v-deep(.contes-quebec)
			background-image url('@/assets/img/badges/contes-quebec.png')
		::v-deep(.contes-atlantique)
			background-image url('@/assets/img/badges/contes-atlantique.png')
		::v-deep(.livre-carcajou)
			background-image url('@/assets/img/books/livre-carcajou.png')
		::v-deep(.livre-sedna)
			background-image url('@/assets/img/books/livre-sedna.png')
		::v-deep(.livre-castor)
			background-image url('@/assets/img/books/livre-castor.png')
		::v-deep(.livre-rose)
			background-image url('@/assets/img/books/livre-rose.png')
		::v-deep(.livre-paul)
			background-image url('@/assets/img/books/livre-paul.png')
		::v-deep(.livre-sirene)
			background-image url('@/assets/img/books/livre-sirene.png')

		.stamp
			margin 16px auto 0 auto
			width round(300px / 2.5)
			height round(385px / 2.5)
	.dots
		height 12px
		flex center
		gap 8px
		margin 24px 0 0 0
		.dot
			width 12px
			height 12px
			border 2px solid #082D37
			border-radius 50%
			&.active
				background-color #082D37
				border none

	.skip-button
		position absolute
		left calc(100% + 16px)
		top 24px
		width 32px
		height 32px
		background center center no-repeat
		background-size contain
		cursor pointer
		&:hover
			transform scale(1.1)
		&:active
			transform scale(1)

	.ui-audio-player-button
		position absolute
		right -40px
		top -40px
		pointer-events auto

</style>
