<template>
	<div class="memory-stamp">
		<div v-bg="illustration" class="image"></div>
		<div v-if="showStars" data-id="stars" :data-id-score="score" class="stars">
			<div v-bg="'user/star-' + (nbStars >= 1 ? nbStars : 0) + '.svg'" class="star" :class="{ empty: nbStars < 1 }"><div :data-id="`${nbStars > 0 ? 'star' : ''}`"></div></div>
			<div v-bg="'user/star-' + (nbStars >= 2 ? nbStars : 0) + '.svg'" class="star" :class="{ empty: nbStars < 2 }"><div :data-id="`${nbStars > 1 ? 'star' : ''}`"></div></div>
			<div v-bg="'user/star-' + (nbStars >= 3 ? nbStars : 0) + '.svg'" class="star" :class="{ empty: nbStars < 3 }"><div :data-id="`${nbStars > 2 ? 'star' : ''}`"></div></div>
		</div>
	</div>
</template>

<script>
import { medias } from '@affordancestudio/engage-game'

export default {
	props: {
		score: { type: Number, default: 0 },
		reward: { type: Object, default: null },
		nbStars: { type: Number, default: 0 },
		showStars: { type: Boolean, default: true }
	},
	computed: {
		illustration() {
			return medias.get({ id: this.reward?.media_id })?.base64
		},
	}
}
</script>

<style lang="stylus" scoped>
.memory-stamp
	absolute -8px
	.image
		absolute 0
		background center center no-repeat
		background-size contain
	.stars
		position absolute
		right -8px
		top 0
		bottom 0
		width 28px
		flex start column
		gap 0
		.star
			width @width
			height @width
			background center center no-repeat
			background-size contain
			background-image url('@/assets/img/user/star-0.svg')
			&.full
				background-image url('@/assets/img/user/star-1.svg')
			&.empty
				opacity 0.75

@media (max-width: 460px)
	.memory-stamp
		width 100%
		left 0
</style>
